.depth_bg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .depth_header_box {
        width: 240px;
        height: 40px;
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .depth_list{
        width: 255px;
        padding: 0;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}