.nor_dlg_bg {

    .nor_content_box {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(248, 248, 248);
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 30px;
        padding-top: 24px;
    }

    
}