.home_bg {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(248, 248, 248);

    .home_left_box {
        width: 100vw;
        height: 100vh;
        border-left-width: thin;
        border-left-color: rgba(128, 128, 128, 0.1);
        border-left-style: solid;

        .home_header_box {
            padding: 5px;
            padding-bottom: 10px;
            height: 80px;
            border-bottom-width: thin;
            border-bottom-color: rgba(128, 128, 128, 0.1);
            border-bottom-style: solid;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
        }
        .home_main_box{
            display:flex;
            flex-direction: column;
        }
    }

    .home_right_box {
        padding: 10px;
        height: 100vh;
        border-left-width: thin;
        border-left-color: rgba(128, 128, 128, 0.1);
        border-left-style: solid;

    }
}